<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">List Karyakartas</div>
        <div class="
              col-lg-6 col-12
              text-end
              d-flex
              justify-content-end
              custom-flex-cloumn-mob
            "></div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-all-details-tab" data-bs-toggle="tab" data-bs-target="#voteralldetail"
                type="button" role="tab" aria-controls="vehicle-details" aria-selected="false"
                @click="redirecttokaryakartas">
                Karyakartas Details
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="voter-report-tab" data-bs-toggle="tab" data-bs-target="#voterreport"
                type="button" role="tab" aria-controls="customer-details" aria-selected="true">
                Reports
            </button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="voterreport" role="tabpanel" aria-labelledby="voter-report-tab">
            <div class="company-section-outer py-3" style="height: calc(100vh - 180px)">
                <div class="row g-3">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="feedback-chart-outer pb-3">
                            <div class="chart-label">Designation wise Report</div>
                            <div>
                                <Chart type="doughnut" :data="chartDataDesignation" :options="lightOptions"
                                    responsive="true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="feedback-chart-outer pb-3">
                            <div class="chart-label">City wise Report</div>
                            <div>
                                <Chart type="doughnut" :data="chartDataCity" :options="lightOptions" responsive="true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="feedback-chart-outer pb-3">
                            <div class="chart-label">Gender wise Report</div>
                            <div>
                                <Chart type="doughnut" :data="chartDataGender" :options="lightOptions" responsive="true" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="card border-success shadow mb-2 bg-white rounded">
                            <div class="card-body">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="msg  in chartDesignationDataValue" :key="msg">
                                        <div class="d-flex justify-content-between"><span>{{ msg.lk55 }}</span><span
                                                class="fw-bold">{{ msg.count
                                                }}</span></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="card border-success shadow mb-2 bg-white rounded">
                            <div class="card-body">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="msg  in chartCityDataValue" :key="msg">
                                        <div class="d-flex justify-content-between"><span>{{ msg.lk59 }}</span><span
                                                class="fw-bold">{{ msg.count
                                                }}</span></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="card border-success shadow mb-2 bg-white rounded">
                            <div class="card-body">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="msg  in chartGenderDataValue" :key="msg">
                                        <div class="d-flex justify-content-between"><span>{{ msg.lk24 ? msg.lk24 : "Undefined" }}</span><span
                                                class="fw-bold">{{ msg.count
                                                }}</span></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="voteralldetail" role="tabpanel" aria-labelledby="voter-all-details-tab"></div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";

export default {
    data() {
        return {
            chartDataDesignation: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            },
            chartDataCity: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            },
            chartDataGender: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            },
            lightOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: "#495057",
                        },
                        display: false,
                    },
                },
            },
            chartGenderDataValue: [],
            chartDesignationDataValue: [],
            chartCityDataValue: [],
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getvoterreportschart();
    },
    methods: {
        redirecttokaryakartas() {
            this.$router.push("/karyakartas/list");
        },
        getvoterreportschart() {
            this.ApiService.getkaryakartasreport().then((data) => {
                if (data.success == true) {
                    this.chartDataCity = {
                        labels: data.citylabels,
                        datasets: [
                            {
                                data: data.citycount,
                                backgroundColor: ["#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#808000",
                                    "#008000",
                                    "#008080",
                                    "#000080",
                                    "#808080",
                                    "#F08080",
                                    "#FF8C00",
                                    "#556B2F",],
                                hoverBackgroundColor: ["#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#808000",
                                    "#008000",
                                    "#008080",
                                    "#000080",
                                    "#808080",
                                    "#F08080",
                                    "#FF8C00",
                                    "#556B2F",],
                            },
                        ],
                    };
                    this.chartCityDataValue = data.citydata;
                    this.chartDataDesignation = {
                        labels: data.labels,
                        datasets: [
                            {
                                data: data.count,
                                backgroundColor: [
                                    "#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#808000",
                                    "#008000",
                                    "#008080",
                                    "#000080",
                                    "#808080",
                                    "#F08080",
                                    "#FF8C00",
                                    "#556B2F",
                                ],
                                hoverBackgroundColor: [
                                    "#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#808000",
                                    "#008000",
                                    "#008080",
                                    "#000080",
                                    "#808080",
                                    "#F08080",
                                    "#FF8C00",
                                    "#556B2F",
                                ],
                            },
                        ],
                    };
                    this.chartDesignationDataValue = data.designationdata;
                    this.chartDataGender = {
                        labels: data.genderlabels,
                        datasets: [
                            {
                                data: data.gendercount,
                                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                            },
                        ],
                    };
                    this.chartGenderDataValue = data.genderdata;
                }
            });
        },
    },
};
</script>
<style scoped>
.company-header-box-outer {
    padding: 9px 16px;
    border: 1px solid #e0e5ed;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 0;
}

.company-legal-name {
    font-family: "AcuminPro-SemiBold";
    letter-spacing: 0px;
    color: #2b2e38;
    font-size: 16px;
    line-height: 20px;
    white-space: break-spaces;
}

.company-detail-box-outer {
    padding: 16px 16px 0;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
}

.branch-detail-box-outer {
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer .branch-row-box {
    padding: 16px;
    border-bottom: 1px solid #e0e5ed;
}

.branch-detail-box-outer .branch-row-box:last-child {
    border-bottom: 0;
}

.customer-label-group {
    margin-bottom: 20px;
}

.form-label-header {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #2b2e38;
    line-height: 16px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    word-break: break-word;
}

.company-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 520px; */
}

.company-section-outer::-webkit-scrollbar {
    display: block;
}

.company-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.custom-outline-whatsapp-btn {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    border-radius: 4px;
    padding: 5px 6px;
    line-height: 10px;
}

.custom-outline-whatsapp-btn .whatsapp-color {
    color: #2ca270;
    font-size: 18px;
}

.custom-outline-call-btn {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    border-radius: 4px;
    padding: 7.5px;
    line-height: 10px;
}

.custom-outline-call-btn .call-btn-color {
    color: #2ca270;
    font-size: 13px;
}

.custom-outline-ellipsis-btn {
    padding: 0;
    border: 0;
}

.text-star-blank {
    color: #c1c5c9;
}

.voter-report-section {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 12px;
    border: 1px solid #e0e5ed;
    border-radius: 4px 4px 0px 0px;
    border-top: 0;
}

.feedback-chart-outer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
}

.feedback-chart-outer .chart-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    padding: 16px;
}
</style>